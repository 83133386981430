import { useCookies } from 'vue3-cookies';
import axios from 'axios';
import store from '@/store/store';

const { cookies } = useCookies();
export const awsPath = process.env.VUE_APP_AWS_PATH;
export const defaultImage = `${process.env.VUE_APP_AWS_PATH}site/images/default-image.webp`;

export default function setImagePath(imageSize, image) {
    if (!image) {
        return defaultImage;
    }

    if (imageSize === 'small' && image.small_aws_url) return awsPath + image.small_aws_url;
    if (imageSize === 'medium' && image.medium_aws_url) return awsPath + image.medium_aws_url;
    if (imageSize === 'compress' && image.compressed_aws_url) return awsPath + image.compressed_aws_url;
    if (image.original_url) return image.original_url;
    return defaultImage;
}

export function preventNullInQSelect(value, oldValue) {
    if (value !== null) {
        return value;
    }
    return oldValue;
}

export function removeUserData() {
    const allCookies = cookies.keys();
    allCookies.forEach((cookie) => cookies.remove(cookie));
    const storeStates = ['storeMyDeals/removeAllProperty', 'storeUserDetails/removeData', 'storeStepsData/removeData'];
    storeStates.forEach((state) => {
        store.commit(state);
    });

    localStorage.clear();
    delete axios.defaults.headers.common.Authorization;
}

export function formCompleteURL(path) {
    if (!path) return false;
    const proxyUrl = process.env.VUE_APP_PROXY_URL;
    const baseURL = proxyUrl ?? window.location.origin;
    const normalizeBaseURL = baseURL.endsWith('/') ? baseURL : `${baseURL}/`;
    const normalizePath = path.startsWith('/') ? path.substring(1) : path;
    return normalizeBaseURL + normalizePath;
}

export function getStoredCurrencyList() {
    const storeCurrency = this.$store.state.storeCurrencyList?.currencyList;
    const currencyList = [];
    if (storeCurrency) {
        storeCurrency.forEach((currency) => {
            currencyList.push({ value: currency.code, label: `${currency.code} ${currency.name} (${currency.symbol})`, symbol: currency.symbol });
        });
    }

    return currencyList;
}

export function getCurrencySymbol() {
    const currencySymbol = this.$store.state.storeUserCurrency?.userCurrency?.symbol;
    if (!currencySymbol) {
        return '$';
    }
    return currencySymbol;
}

export function getCurrentUserId() {
    const userId = this.$store.state.storeUserDetails.userData?.user_id;

    if (userId) {
        return userId;
    }
    return false;
}

export function getHiddenProperties() {
    const hiddenProperties = store.state.storeHideProperties.properties;
    if (!hiddenProperties) {
        return [];
    }
    return hiddenProperties;
}

function formatDiscountAmount(amount) {
    if (amount === 0) {
        return 0;
    }
    const suffixes = ['', 'k', 'm', 'b'];
    let tier = Math.floor(Math.log10(amount) / 3);

    if (tier >= suffixes.length) {
        tier = suffixes.length - 1;
    }

    const suffix = suffixes[tier];
    const scale = 10 ** (tier * 3);
    const scaled = amount / scale;

    return scaled.toFixed(0) + suffix;
}

export function formatDiscountText(amount, currencySymbol, isDisplayNightWord = true) {
    const nightWord = isDisplayNightWord ? ' nt' : '';
    return `Save ${currencySymbol}${formatDiscountAmount(amount)}${nightWord}`;
}

export function formatDiscountPercentageText(discountPercentage) {
    return `Save ${Math.round(discountPercentage)}%`;
}

export function formatGeocodeLocation(geocodeApiResults) {
    if (!Array.isArray(geocodeApiResults) || geocodeApiResults.length === 0) {
        return ''; // Return an empty string if the response is not valid or no results are found
    }

    // Get the address_components and resultTypes from the first item in the response
    const result = geocodeApiResults[0];
    const addressComponents = result.address_components;
    const resultTypes = result.types;
    const formattedAddress = result.formatted_address; // For fallback condition

    // Check if addressComponents is defined and not empty
    if (!addressComponents || addressComponents.length === 0) {
        return ''; // Return an empty string if address_components is not valid or empty
    }

    // Define the desired types to look for in address components
    const desiredTypes = [
        'locality', // City or local area
        'administrative_area_level_1', // State or province
        'country', // Country
    ];

    // Function to check if any of the desired types are present in resultTypes
    const isAnyDesiredTypePresent = () => desiredTypes.some((type) => resultTypes.includes(type));

    const formattedParts = []; // Array to hold formatted address parts
    let matchingComponent = null;

    // If none of the desired types are present in resultTypes
    if (!isAnyDesiredTypePresent()) {
        // Find an address component that matches all resultTypes
        matchingComponent = addressComponents.find((component) => resultTypes.every((type) => component.types.includes(type)));

        // If a matching component is found, add its long_name to formattedParts
        if (matchingComponent) {
            formattedParts.push(matchingComponent.long_name);
        }
    }

    // If no matching component was found, prepend 'sublocality_level_1' to desiredTypes
    if (!matchingComponent) {
        desiredTypes.unshift('sublocality_level_1');
    }

    // Iterate through address components to find and add desired parts
    addressComponents.forEach((component) => {
        if (component.types.includes('political')) {
            // Iterate through desired types and add components that match
            desiredTypes.forEach((type) => {
                if (component.types.includes(type)) {
                    formattedParts.push(component.long_name);
                }
            });
        }
    });

    // Return formatted address if formattedParts is empty; otherwise return the prepared location string
    return formattedParts.length === 0 ? formattedAddress : formattedParts.join(', ');
}

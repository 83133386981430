<template>
    <div class="pageNotFound q-px-container">
        <h1 class="jumboTest">Oops!</h1>
        <h2>We can't seem to find the page you're looking for.</h2>
        <div class="errorCode">Error code: 404</div>
        <div>Here are some helpful links instead:</div>
        <ul class="helpfulLinks">
            <li><router-link :to="{ name: 'HomeView' }">Home</router-link></li>
            <li v-if="getUser">
                <router-link v-if="getUser.is_traveler" :to="{ name: 'TravelerHome' }">Dashboard</router-link>
                <router-link v-else :to="{ name: 'ManagerHome' }">Dashboard</router-link>
            </li>
        </ul>
    </div>
</template>

<script>
import _ from 'lodash';
import { useCookies } from 'vue3-cookies';

const { cookies } = useCookies();

export default {
    name: 'pageNotFound',
    data() {
        return {
            metaData: {
                title: 'getawayGoGo | Last Minute Vacation Rental Discounts & Deals',
                description: 'Best last minute vacation rental deals on beach rentals, mountain cabins, villas, apartments & lake houses with NO booking service fee - getawayGoGo',
                hideCanonicalURL: true,
            },
        };
    },
    computed: {
        getUser() {
            if (localStorage.getItem('userDetails') && cookies.isKey('user_login')) {
                return _.get(this.$store, 'state.storeUserDetails.userData');
            }
            return null;
        },
    },
};
</script>

<style lang="scss" scoped>
.pageNotFound {
    margin: 3rem auto;
    text-align: center;

    h1.jumboTest {
        margin: unset;
        padding-bottom: .5rem;
        font-size: 9.063rem;
        font-weight: 500;
    }

    h2 {
        margin: unset;
        margin-bottom: .5rem;
        font-size: 2rem;
        font-weight: 500;
    }

    .errorCode {
        margin-bottom: 1rem;
        font-size: 1rem;
    }

    ul.helpfulLinks {
        margin: unset;
        padding: unset;
        list-style: none;
    }

    a {
        text-decoration: none;
        color: #484848;

        &:hover {
            color: #888;
        }
    }
}

@media screen and (max-width: $breakpoint-xs) {
    .pageNotFound {
        h1.jumboTest {
            font-size: 6.25rem;
        }

        h2 {
            font-size: 1.25rem;
        }
    }
}

</style>

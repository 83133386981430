export default [{
    path: '/traveler',
    name: 'TravelerDashboard',
    component: () => import('./TravelerDashboard.vue'),
    meta: {
        requiresAuth: true,
        isTraveler: true,
        title: 'Home | Traveler - getawayGoGo',
    },
    redirect: { name: 'TravelerHome' },
    children: [
        {
            name: 'TravelerHome',
            path: 'dashboard',
            component: () => import('./TravelerHome.vue'),
        }, {
            name: 'TravelerInbox',
            path: 'inbox',
            component: () => import('./TravelerInbox.vue'),
            meta: { title: 'My Message | Traveler - getawayGoGo' },
        }, {
            name: 'TravelerReservation',
            path: 'my-reservation',
            component: () => import('./TravelerReservation.vue'),
            meta: { title: 'Reservations | Traveler - getawayGoGo' },
        }, {
            name: 'TravelerDeals',
            path: 'my-deals',
            component: () => import('./TravelerDeals.vue'),
            meta: { title: 'My Deals | Traveler - getawayGoGo' },
        }, {
            name: 'TravelerRadar',
            path: 'gogo-radar',
            component: () => import('./TravelerRadar.vue'),
            meta: { title: 'GoGo Radar | Traveler - getawayGoGo' },
        }, {
            name: 'TravelerProfile',
            path: 'profile',
            component: () => import('./TravelerProfile.vue'),
            meta: { title: 'Profile | Traveler - getawayGoGo' },
        },
    ],
}];

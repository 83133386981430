import _ from 'lodash';
import { createRouter, createWebHistory } from 'vue-router';
import { useCookies } from 'vue3-cookies';
import store from '../store/store';
import connectRoutes from '../components/connect/router';
import travelerRoutes from '../components/traveler/router';
import managerRoutes from '../components/manager/router';
import HomeView from '../components/home/HomeView.vue';
import pageNotFound from '../components/layouts/404.vue';

const { cookies } = useCookies();

const baseRoutes = [{
    path: '/',
    name: 'HomeView',
    component: HomeView,
    meta: {
        title: 'getawayGoGo | Last Minute Vacation Rental Deals | Homes, Cabins, Condos',
    },
}, {
    path: '/about-us',
    name: 'AboutUs',
    component: () => import('../components/AboutUs.vue'),
    meta: {
        title: 'About Us | Last Minute Vacation Rental Deals - getawayGoGo',
    },
}, {
    path: '/terms-of-service',
    name: 'TermsOfService',
    component: () => import('../components/termsOfService.vue'),
    meta: {
        title: 'Terms of service for travelers and managers - getawayGoGo',
    },
}, {
    path: '/privacy-policy',
    name: 'PrivacyPolicy',
    component: () => import('../components/privacyPolicy.vue'),
    meta: {
        title: 'Privacy policy for travelers and managers - getawayGoGo',
    },
}, {
    path: '/pricing',
    name: 'PMSPricing',
    component: () => import('../components/pricing.vue'),
    meta: {
        title: 'Pricing for property managers - getawayGoGo',
    },
}, {
    path: '/login',
    name: 'login',
    component: () => import('../components/login.vue'),
    meta: {
        isLogin: true,
        title: 'Login Page | Traveler - getawayGoGo',
    },
}, {
    path: '/manager-login',
    name: 'managerLogin',
    component: () => import('../components/managerLogin.vue'),
    meta: {
        isLogin: true,
        title: 'Login Page | Manager - getawayGoGo',
    },
}, {
    path: '/s',
    name: 'search',
    component: () => import('../components/layouts/search.vue'),
    meta: {
        title: 'Search - getawayGoGo',
    },
}, {
    path: '/vacation-rentals/:country',
    name: 'countryPage',
    component: () => import('../components/layouts/CityStateCountryPage.vue'),
    meta: {
        title: 'Last Minute Vacation Rental',
    },
}, {
    path: '/vacation-rentals/:country/:state',
    name: 'statePage',
    component: () => import('../components/layouts/CityStateCountryPage.vue'),
    meta: {
        title: 'Last Minute Vacation Rental',
    },
}, {
    path: '/vacation-rentals/:country/:state/:city',
    name: 'cityPage',
    component: () => import('../components/layouts/CityStateCountryPage.vue'),
    meta: {
        title: 'Last Minute Vacation Rental',
    },
}, {
    path: '/vacation-rentals/:country/:state/:city/:propertyId',
    name: 'propertyDetail',
    component: () => import('../components/layouts/propertyDetail/propertyDetail.vue'),
    meta: {
        title: 'Property Details Page - getawayGoGo',
    },
}, {
    path: '/rentals/:propertyId',
    name: 'rentalsPage',
    component: () => import('../components/layouts/propertyDetail/propertyDetail.vue'),
    meta: {
        title: 'Property Details Page - getawayGoGo',
    },
}, {
    path: '/contact',
    name: 'managerContact',
    component: () => import('../components/contactUs.vue'),
    meta: {
        title: 'Contact Us - getawayGoGo',
        requiresAuth: true,
        isManager: true,
    },
}, {
    path: '/payments/booking/:propertyId',
    name: 'reserveNow',
    component: () => import('../components/layouts/reservePage.vue'),
    meta: {
        requiresAuth: true,
        title: 'Reserve Now - Enter Basic Details - getawayGoGo',
    },
}, {
    path: '/payments/contact/:propertyId',
    name: 'contactManager',
    component: () => import('../components/layouts/contactPropertyManager.vue'),
    meta: {
        requiresAuth: true,
        title: 'Contact Property Manager - getawayGoGo',
    },
}, {
    path: '/traveler/booking-confirm',
    name: 'TravelerBooking',
    component: () => import('../components/layouts/TravelerBooking.vue'),
    meta: {
        requiresAuth: true,
        title: 'Booking Confirm',
    },
}, {
    path: '/users/confirm_email',
    name: 'confirmEmail',
    component: () => import('../components/confirmEmail.vue'),
    meta: {
        title: 'Confirm Email - getawayGoGo',
    },
}, {
    path: '/password/reset/:id',
    name: 'resetPassword',
    component: () => import('../components/resetPassword.vue'),
    meta: {
        isLogin: true,
        title: 'Reset Password - getawayGoGo',
    },
}, {
    path: '/:pathMatch(.*)*',
    name: 'pageNotFound',
    component: pageNotFound,
},
{
    path: '/ownerrez/:pmsId',
    name: 'ownerRezPage',
    component: () => import('../components/layouts/propertyDetail/propertyDetail.vue'),
    meta: {
        title: 'Property Details Page - getawayGoGo',
    },
},
{
    path: '/partner/vacation-rentals/:offerId',
    name: 'HomeToGoPage',
    component: () => import('../components/layouts/propertyDetail/propertyDetail.vue'),
    meta: {
        title: 'Property Details Page - getawayGoGo',
    },
},
{
    path: '/users/set-password',
    name: 'setPassword',
    component: () => import('../components/setBookingPalPassword.vue'),
    meta: {
        title: 'Set Password - getawayGoGo',
    },
}];

const routes = baseRoutes.concat(connectRoutes, travelerRoutes, managerRoutes);
const router = createRouter({
    mode: 'history',
    history: createWebHistory(),
    routes,
    scrollBehavior() {
        return { top: 0 };
    },
});

// This callback runs before every route change, including on page load.
router.beforeEach((to, from, next) => {
    if (to.meta.requiresAuth && !cookies.isKey('user_login')) {
        if (to.meta.isManager) {
            return next({ name: 'managerLogin' });
        }
        return next({ name: 'login' });
    }
    if (to.meta.isTraveler && !_.get(store, 'state.storeUserDetails.userData.is_traveler')) {
        return next({ name: 'ManagerHome' });
    }
    if (to.meta.isManager && _.get(store, 'state.storeUserDetails.userData.is_traveler')) {
        return next({ name: 'TravelerHome' });
    }
    if (to.meta.isLogin && cookies.isKey('user_login')) {
        if (_.get(store, 'state.storeUserDetails.userData.is_traveler')) {
            return next({ name: 'TravelerHome' });
        }
        return next({ name: 'ManagerHome' });
    }
    if (to.meta.checkSteps && _.get(store, 'state.storeStepsData.userStepsData') && !_.isEmpty(store.state.storeStepsData.userStepsData.step_7)) {
        return next({ name: 'ManagerHome' });
    }

    const nearestWithTitle = to.matched.slice().reverse().find((r) => r.meta && r.meta.title);
    const nearestWithMeta = to.matched.slice().reverse().find((r) => r.meta && r.meta.metaTags);
    const previousNearestWithMeta = from.matched.slice().reverse().find((r) => r.meta && r.meta.metaTags);
    if (nearestWithTitle) {
        document.title = nearestWithTitle.meta.title;
    } else if (previousNearestWithMeta) {
        document.title = previousNearestWithMeta.meta.title;
    }
    Array.from(document.querySelectorAll('[data-vue-router-controlled]')).map((el) => el.parentNode.removeChild(el));
    if (!nearestWithMeta) return next();
    nearestWithMeta.meta.metaTags.map((tagDef) => {
        const tag = document.createElement('meta');
        Object.keys(tagDef).forEach((key) => {
            tag.setAttribute(key, tagDef[key]);
        });
        tag.setAttribute('data-vue-router-controlled', '');
        return tag;
    }).forEach((tag) => document.head.appendChild(tag));
    return next();
});

export default router;

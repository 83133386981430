<template>
    <q-scroll-observer @scroll="scrollObserver" />
    <q-carousel v-model="slide" class="mainSlider" style="overflow: inherit; height: 510px;" infinite :autoplay="5000" animated>
        <q-carousel-slide v-for="(img, i) in images" :key="(i + 1) " :name="(i + 1)" :img-src="img" loading="lazy" />
        <template v-slot:control>
            <div class="absolute absolute-center row justify-center" :class="{ 'q-pa-md full-width': $q.screen.lt.sm }">
                <HomeSliderContent />
            </div>
        </template>
    </q-carousel>
</template>

<style lang="scss" scoped>
* {
    transition: all .4s ease;
}

@media screen and (min-width:$breakpoint-md) {
    .mainSlider {
        height: 575px !important;
    }
}
</style>

<script>
import { defineAsyncComponent } from 'vue';

export default {
    name: 'HomeSlider',
    components: {
        HomeSliderContent: defineAsyncComponent(() => import('./HomeSliderContent.vue')),
    },
    props: {
        countrySlideImgProp: {
            type: Array,
            default() {
                return [];
            },
        },
    },
    data() {
        const awsPath = process.env.VUE_APP_AWS_PATH;
        const images = [
            `${awsPath}site/banners/banner17.webp`,
            `${awsPath}site/banners/banner18.webp`,
            `${awsPath}site/banners/banner19.webp`,
            `${awsPath}site/banners/banner20.webp`,
            `${awsPath}site/banners/banner21.webp`,
            `${awsPath}site/banners/banner22.webp`,
        ];
        return {
            slide: 1,
            images: this.countrySlideImgProp.length ? this.countrySlideImgProp : images,
        };
    },
    methods: {
        scrollObserver() {
            this.emitter.emit('homeSliderScrollEvent');
        },
        preloadImg(imgUrl) {
            const img = new Image();
            img.src = imgUrl;
        },
        setBannerImg() {
            this.images.forEach((img) => {
                this.preloadImg(img);
            });
        },
    },
    mounted() {
        this.setBannerImg();
    },
};
</script>

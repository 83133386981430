const customValidations = {
    generalValidation(val, fieldName) {
        if (!val) return `Please enter ${fieldName}.`;

        if (val) {
            const rule = /^[a-zA-Z0-9&.,\-_''\s]+$/;
            return rule.test(val) || 'Allowed only special characters (-), (&), (_), (.), (,), (\') and space.';
        }
        return true;
    },
    emailValidation(val, article = 'an', fieldName = 'Email') {
        if (!val) return `Please enter ${article} ${fieldName} Address.`;

        if (val) {
            const rule = /^(?!.*")[0-9A-Za-z]+(?:[.-_+][0-9A-Za-z]+)*(?:\+[0-9A-Za-z]+)*(?:_[0-9A-Za-z]+)*(?:-[0-9A-Za-z]+)*(?:\.[0-9A-Za-z]+)*@[0-9A-Za-z]+(?:[.-][0-9A-Za-z]+)*(?:[a-zA-Z0-9]|-(?!-_.)[0-9A-Za-z])+(?:\.[a-z]{2,})+/;
            return rule.test(val) || `Please enter a valid ${fieldName} Address.`;
        }
        return true;
    },
    cardNameValidation(val) {
        if (!val) return 'Please enter a Cardholder Name.';

        if (val) {
            const rule = /^[(a-zA-z)\s]*$/;
            return rule.test(val) || 'Please enter a valid Cardholder Name.';
        }
        return true;
    },
    postalCodeValidation(val, checkRequire = true) {
        if ((!val || val.length < 3) && (checkRequire || val)) return 'Please enter a Postal Code.';

        if (val) {
            const rule = /^(?!.*(?:[ -]{2,}))[A-Za-z0-9-]{1,5}[ -]?[A-Za-z0-9-]{1,5}$/;
            return rule.test(val) || 'Please enter a valid Postal Code.';
        }
        return true;
    },
    passwordValidation(val) {
        if (!val) return 'Please enter Password.';
        if (val.length < 8) return 'Please enter minimum 8 characters.';
        if (val.length > 20) return 'The password may not be greater than 20 characters.';
        return true;
    },
    allowOneSpace(value) {
        return value.replace(/ +/g, ' ');
    },
    validatePrice(val, fieldName) {
        if (val) {
            const rule = /^(?!0\d+(\.\d+)?$)([1-9]\d*(\.\d+)?)$/;
            return rule.test(val) || `Please enter valid ${fieldName}.`;
        }
        return true;
    },
};

export default customValidations;

<template>
    <q-dialog persistent v-model="userLogin" transition-duration="500" @hide="$emit('loginModalhidden')">
        <q-card v-if="userLoginPopup" class="generalModelStyle loginPopup">
            <div class="row no-wrap items-start">
                <q-toolbar-title class="text-center q-pr-none">
                    <img :src="`${awsPath}site/images/logo.webp`" alt="logo">
                </q-toolbar-title>
                <q-btn flat dense color="ggg-red" class="closeIcon btn--no-hover" icon="close" size="1rem" @click="userLogin = false;" v-close-popup />
            </div>
            <q-separator inset spaced="lg" />
            <q-form ref="loginFormRef" @submit="handleLogin">
                <div class="userText">{{ userType === 'traveler' ? 'Traveler Login' : 'Property Manager Login' }}</div>
                <div class="q-mt-lg">
                    <div v-if="loginErr" class="errMsg"><q-icon name="o_cancel" class="q-pr-xs" />{{ loginErr }}</div>
                    <q-input
                        ref="userEmailRef"
                        v-model="email"
                        placeholder="Email Address"
                        outlined
                        no-error-icon
                        hide-bottom-space
                        lazy-rules
                        :rules="[val => customValidations.emailValidation(val)]">
                        <template v-slot:append>
                            <q-icon name="o_mail" />
                        </template>
                    </q-input>
                    <q-input
                        ref="userPassRef"
                        v-model="password"
                        :type="isPwd ? 'password' : 'text'"
                        placeholder="Password"
                        class="q-mt-md"
                        outlined
                        no-error-icon
                        hide-bottom-space
                        lazy-rules
                        :rules="[val => customValidations.passwordValidation(val)]">
                        <template v-slot:append>
                            <q-icon :name="isPwd ? 'o_visibility_off' : 'o_visibility'" class="cursor-pointer" @click="isPwd = !isPwd" />
                        </template>
                    </q-input>
                    <div class="row q-mt-md">
                        <div class="col">
                            <q-checkbox v-model="rememberMe" color="ggg-red" dense><span class="rememberText">Remember me</span></q-checkbox>
                        </div>
                        <div class="col text-right">
                            <button type="button" class="nostyle-button gogo-link" @click="backToLogin()">Forgot Password?</button>
                        </div>
                    </div>
                </div>

                <div class="text-center">
                    <q-btn no-caps dense type="submit" :loading="submitting" :ripple="false" class="popupLoginbtn btn btn--no-hover" label="Log In" />
                </div>
            </q-form>

            <q-separator />

            <div class="bottomSection">
                <div>Don’t have an account?
                    <router-link class="gogo-link" :to="userType === 'traveler' ? { name: 'TravelerSignup' } : { name: 'ManagerSignup' }" @click="userLogin = false">Sign Up</router-link>
                </div>
                <div>OR,</div>
                <div v-if="userType === 'traveler'">Are you a Property Manager?
                    <button type="button" class="nostyle-button gogo-link inline-block" @click="resetLoginForm('manager')">Log In Here</button>
                </div>
                <div v-else>Are you a Traveler?
                    <button type="button" class="nostyle-button gogo-link inline-block" @click="resetLoginForm('traveler')">Log In Here</button>
                </div>
            </div>
        </q-card>

        <q-card v-else class="generalModelStyle resetPass">
            <div class="text-center">
                <img :src="`${awsPath}site/images/logo.webp`" alt="logo">
            </div>
            <q-separator spaced="lg" />
            <q-form @submit="forgotPassword">
                <div class="resetHeading">Reset Password</div>
                <p class="resetDesc">Enter the email address associated with your account, and we'll email you a link to reset your password.</p>

                <q-input
                    ref="resetEmailRef"
                    v-model.trim="resetEmail"
                    placeholder="Email Address"
                    outlined
                    no-error-icon
                    hide-bottom-space
                    lazy-rules
                    @focus="resetEmailErrMsg = null, resetEmailSuccessMsg = null"
                    :rules="[val => customValidations.emailValidation(val)]">
                    <template v-slot:append>
                        <q-icon name="o_mail" />
                    </template>
                </q-input>
                <div v-if="resetEmailErrMsg" class="resetEmailErr">{{ resetEmailErrMsg }}</div>
                <div v-if="resetEmailSuccessMsg" class="resetEmailSuccess">{{ resetEmailSuccessMsg }}</div>
                <div class="row no-wrap justify-between items-center resetButtons">
                    <a class="backToLogin row items-center" @click="userLoginPopup = true, resetEmail = null, resetEmailErrMsg = null, setResetButtonLabel = 'Send Reset Link'">
                        <q-icon name="arrow_back_ios" size="1.25rem" />
                        <span class="col">Back to Login</span>
                    </a>
                    <q-btn no-caps :ripple="false" type="submit" :loading="submitting" class="resetLinkBtn btn btn--no-hover" :label="setResetButtonLabel" />
                </div>
            </q-form>
        </q-card>
    </q-dialog>
</template>

<style lang="scss" scoped>
.nostyle-button {
    all: unset;
}

.rememberText {
    font-size: .958rem;
}

.bottomSection {
    padding-top: 1rem;
    font-size: .958rem;
    text-align: center;
}

.gogo-link {
    text-decoration: none;
    color: $ggg-red;
    font-size: .958rem;
    cursor: pointer;

    &:hover {
        text-decoration: underline;
    }
}

.loginPopup {
    width: 400px;
    padding: 1rem;

    img {
        max-width: 58%;
    }

    .closeIcon {
        position: absolute;
        top: .5rem;
        right: .5rem;
    }

    .userText {
        font-size: 1.463rem;
        font-weight: 600;
        text-align: center;
    }

    p {
        margin-bottom: unset;
        font-size: 0.958em;
    }

    .popupLoginbtn {
        margin: 1rem 0;
        padding: .25rem 2rem;
        font-size: 1rem;
        border-radius: 1.25rem;
        background-color: #d33;
    }

    .errMsg {
        color: $negative;
        font-size: .958rem;
        display: flex;
        align-items: center;
        margin-bottom: 0.5rem;
    }
}

.resetPass {
    width: 400px;
    padding: 1rem 1.5rem;

    img {
        max-width: 58%;
        height: auto;
    }

    .resetHeading {
        margin-bottom: 1rem;
        font-size: 1.463rem;
        font-weight: 600;
        text-align: center;
    }

    .resetDesc {
        font-size: .958rem;
    }

    .resetEmailSuccess {
        padding-top: .5rem;
        font-size: .875rem;
        line-height: 1.2;
        color: #008000;
    }

    .resetEmailErr {
        margin-top: .25rem;
        font-size: .875rem;
        line-height: 1.2;
        color: $ggg-red;
    }
}

:deep(.q-field .q-field__control) {
    color: #dbdbdb;

    &:hover::before {
        border-color: unset;
    }
}

.resetButtons {
    padding-top: 1rem;

    .backToLogin {
        padding-right: .5rem;
        font-size: .958rem;
        color: $ggg-red;
        cursor: pointer;
    }

    .resetLinkBtn {
        padding: .25rem 2rem;
        font-size: .958rem;
        border-radius: 1.25rem;
    }
}

@media only screen and (max-width: 420px) {

    .resetButtons .backToLogin,
    .resetButtons .resetLinkBtn {
        line-height: 1.2;
    }
}
</style>

<script>
import _ from 'lodash';
import { useCookies } from 'vue3-cookies';
import { getHiddenProperties } from '@/assets/js/commonFunctions';

const { cookies } = useCookies();

export default {
    name: 'userLogin',
    emits: ['loginModalhidden'],
    props: {
        prop_user_type: {
            type: String,
            default: '',
        },
        prop_show_modal: {
            type: Boolean,
            default: false,
        },
        prop_login_redirect: {
            type: String,
            default: null,
        },
    },
    watch: {
        prop_show_modal(newVal) {
            this.userLogin = newVal;
        },
        prop_user_type(newVal) {
            this.userType = newVal;
        },
        prop_login_redirect(newVal) {
            this.loginRedirect = newVal;
        },
        password(newValue) {
            if (newValue) this.password = newValue.replace(/\s/g, '');
        },
    },
    data() {
        return {
            userLogin: this.prop_show_modal,
            userType: this.prop_user_type,
            loginRedirect: this.prop_login_redirect,
            email: null,
            password: null,
            isPwd: true,
            rememberMe: false,
            resetEmail: null,
            userLoginPopup: true,
            userDetails: null,
            loginErr: null,
            submitting: false,
            resetEmailSuccessMsg: null,
            resetEmailErrMsg: null,
            setResetButtonLabel: 'Send Reset Link',
            metaData: {
                title: 'Log In - getawayGoGo',
                description: 'Log In - getawayGoGo',
            },
        };
    },
    computed: {
        myDealsIds() {
            return _.get(this.$store, 'state.storeMyDeals.properties.length') ? _.get(this.$store, 'state.storeMyDeals.properties') : [];
        },
        hiddenPropertiesIds() {
            return getHiddenProperties();
        },
    },
    methods: {
        handleLogin() {
            if (cookies.isKey('user_login')) {
                this.emitter.emit('userLogout');
            }

            this.submitting = true;
            const payload = {
                email: this.email,
                password: this.password,
                user_type: this.userType,
                currency_code: _.get(this.$store, 'state.storeUserCurrency.userCurrency.code'),
                ...(_.get(cookies.get('viewed_properties'), 'length') ? { viewed_properties_data: cookies.get('viewed_properties').split(',').map((id) => parseInt(id, 10)) } : {}),
                ...(this.hiddenPropertiesIds && this.hiddenPropertiesIds.length ? { hidden_property_ids: cookies.get('hiddenPropertyList') } : {}),
                ...(this.myDealsIds && this.myDealsIds.length > 0 ? { saveDealsData: JSON.parse(localStorage.getItem('my_deals')) } : {}),
            };

            this.axios.post('/api/login', payload).then((response) => {
                this.userDetails = response.data.data;
                cookies.set('user_login', 1, 0);
                this.$store.commit('storeUserDetails/addData', this.userDetails);
                localStorage.setItem('token', this.userDetails.access_token);

                if (this.userDetails?.hidden_property_ids) {
                    this.$store.commit('storeHideProperties/addAllProperties', this.userDetails.hidden_property_ids);
                }

                if (!this.userDetails.is_traveler) {
                    this.$store.commit('storeStepsData/addData', this.userDetails.property_manager.signup_step_status);
                }

                if (_.get(this.userDetails, 'currency_code')) {
                    const currency = { code: this.userDetails.currency_code, symbol: this.userDetails.currency_symbol, value: this.userDetails.currency_code };
                    this.$store.commit('storeUserCurrency/addCurrency', currency);
                }

                setTimeout(() => {
                    this.userRedirect();
                }, 0);
                this.userLogin = false;
            }).catch((error) => {
                const errorResponse = error.response;
                if (errorResponse.status === 401) [this.loginErr] = errorResponse.data.errors;
            }).finally(() => {
                this.submitting = false;
            });
        },
        userRedirect() {
            if (this.userDetails.is_traveler) {
                if (this.loginRedirect) {
                    this.$router.go({ name: this.loginRedirect });
                } else if (cookies.isKey('last_viewed_data')) {
                    const lastViewedData = cookies.get('last_viewed_data');
                    this.$router.go({ name: lastViewedData.type });
                } else {
                    this.$router.push({ name: 'TravelerHome' });
                }
            } else if (_.get(this.userDetails, 'property_manager.signup_step_status') && _.get(this.userDetails, 'property_manager.signup_step_status.step_7')) {
                this.$router.push({ name: 'ManagerHome' });
            } else {
                this.$router.push({ name: 'ManagerSteps' });
            }
        },
        backToLogin() {
            this.userLoginPopup = false;
            this.resetEmailSuccessMsg = null;
            this.email = null;
            this.password = null;
            this.rememberMe = false;
            this.loginErr = null;
        },
        forgotPassword() {
            this.resetEmailSuccessMsg = null;
            this.submitting = true;
            this.resetEmailErrMsg = null;
            this.setResetButtonLabel = 'Retry';
            const payload = {
                email: this.resetEmail,
                is_traveler: this.userType === 'traveler' ? 1 : 0,
            };
            this.axios.post('/api/forgotpassword', payload).then((response) => {
                this.resetEmailSuccessMsg = response.data.message;
                this.resetEmail = null;
                this.$refs.resetEmailRef.resetValidation();
                this.setResetButtonLabel = 'Send Reset Link';
            }).catch((error) => {
                const errorResponse = error.response;
                if (errorResponse.status === 422) [this.resetEmailErrMsg] = errorResponse.data.errors;
            }).finally(() => {
                this.submitting = false;
            });
        },
        resetLoginForm(userType) {
            this.userType = userType;
            this.userLogin = false;
            this.loginErr = null;
            this.email = null;
            this.password = null;
            setTimeout(() => {
                this.userLogin = true;
            }, 500);
        },
    },
    created() {
        const loginRoutes = ['login', 'travelerLogin'];
        if (loginRoutes.includes(this.$route.name) && !cookies.isKey('user_login')) {
            this.userLogin = true;
            this.userType = 'traveler';
        }
    },
};
</script>

import './styles/quasar.scss';
import Notify from 'quasar/src/plugins/Notify.js';;
import '@quasar/extras/material-icons/material-icons.css';
import '@quasar/extras/material-icons-outlined/material-icons-outlined.css';
import '@quasar/extras/fontawesome-v6/fontawesome-v6.css';

export default {
    config: {
        notify: {},
    },
    plugins: [
        Notify,
    ],
};

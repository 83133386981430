<template>
    <div class="homePageDeals">
        <HomePropertyLists sectionNameProp="bestDeals" />
        <FindLastMinuteDeals :isHomeProp="true" />
        <HomeToGoPropertyList />
    </div>
</template>

<script>
import { defineAsyncComponent } from 'vue';

export default {
    name: 'HomePageDeals',
    components: {
        HomePropertyLists: defineAsyncComponent(() => import('./HomePropertyList.vue')),
        FindLastMinuteDeals: defineAsyncComponent(() => import('./FindLastMinuteDeals.vue')),
        HomeToGoPropertyList: defineAsyncComponent(() => import('./HomeToGoPropertyList.vue')),
    },
};
</script>

<style lang="scss" scoped>
@media screen and (min-width:1024px) {
    .homePageDeals {
        margin-top: -1.5rem;
    }
}
</style>

<template>
    <div class="homeView">
        <HomeSlider />
        <homeFeatures />

        <q-separator size="2px" />

        <q-page class="q-px-container">
            <HomePageDeals />
            <HomeFavouritePlaces />
        </q-page>
        <MobileAppSection v-if="showMobileAppSection" :sectionPageIsHomeProp='true' />
        <div class="q-px-container">
            <LastMinuteDestinations />
        </div>
    </div>
</template>

<script>
import { defineAsyncComponent } from 'vue';
import HomeSlider from './HomeSlider.vue';
import homeFeatures from '../layouts/homeFeatures.vue';
import HomePageDeals from './HomePageDeals.vue';

export default {
    name: 'HomePage',
    components: {
        HomeSlider,
        homeFeatures,
        HomePageDeals,
        HomeFavouritePlaces: defineAsyncComponent(() => import('./HomeFavouritePlaces.vue')),
        MobileAppSection: defineAsyncComponent(() => import('./MobileAppSection.vue')),
        LastMinuteDestinations: defineAsyncComponent(() => import('./LastMinuteDestinations.vue')),
    },
    data() {
        return {
            showMobileAppSection: !!+process.env.VUE_APP_MOBILE_APP,
            metaData: {
                title: 'getawayGoGo | Last Minute Vacation Rental Discounts & Deals',
                description: 'Best last minute vacation rental deals on beach rentals, mountain cabins, villas, apartments & lake houses with NO booking service fee - getawayGoGo',
                schemaDesc: 'Best last minute vacation rental deals on beach rentals, mountain cabins, villas, apartments & lake houses with NO booking service fee - getawayGoGo',
            },
        };
    },
    provide: {
        sliderTitleText: () => 'Last Minute',
    },
};
</script>

import useMeta from 'quasar/src/composables/use-meta.js';;

const metaTags = {
    methods: {
        setMetaTags() {
            const { metaData } = this;
            if (!metaData) return false;

            const { title } = metaData;
            const { description } = metaData;
            const schemaDesc = metaData.schemaDesc ?? description;
            const pageURL = window.location.origin + window.location.pathname;
            const metaImage = `${this.awsPath}site/images/beach.webp`;

            return {
                title,
                meta: {
                    title: { name: 'title', content: title },
                    description: { name: 'description', content: description },
                    ogTitle: { property: 'og:title', content: title },
                    ogDescription: { property: 'og:description', content: description },
                    ogImage: { property: 'og:image', content: metaImage },
                    ...(!metaData?.hideCanonicalURL ? { ogURL: { property: 'og:url', content: pageURL } } : {}),

                    /* Twitter meta tags */
                    twitterTitle: { property: 'twitter:title', content: title },
                    twitterDescription: { property: 'twitter:description', content: description },
                    twitterImage: { property: 'twitter:image', content: metaImage },
                    ...(!metaData?.hideCanonicalURL ? { twitterURL: { property: 'twitter:url', content: pageURL } } : {}),
                },
                script: {
                    ldJson: {
                        type: 'application/ld+json',
                        innerHTML: `{ "@context": "http://www.schema.org", "@type": "TravelAgency", "name": "getawayGogo", "description": "${schemaDesc}", "address": { "@type": "PostalAddress", "addressLocality": "San Clemente", "addressRegion": "California", "addressCountry": "United States", "postalCode": "92672" }}`,
                    },
                },
                ...(!metaData?.hideCanonicalURL ? {
                    link: { canonical: { rel: 'canonical', href: pageURL } },
                } : {}),
            };
        },
    },
    created() {
        if (this.metaData) {
            useMeta(this.setMetaTags());
        }
    },
};

export default metaTags;

import axios from 'axios';
import store from '@/store/store';
import { removeUserData } from '@/assets/js/commonFunctions';

const excludeRoutes = ['/api/login'];

axios.interceptors.request.use(
    (config) => {
        // check and set auth token for private routes
        const token = localStorage.getItem('token');

        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        }

        return config;
    },
);

axios.interceptors.response.use(
    (response) => response,
    (error) => {
        // exclude routes to check on error on middleware
        if (excludeRoutes.includes(error.config?.url)) return Promise.reject(error);

        // Handle error response for unauthorized routes
        if (error.response?.status === 401) {
            const errorResponse = error.response.data.data;
            const isRefreshToken = Object.prototype.hasOwnProperty.call(errorResponse, 'refresh_token');

            if (isRefreshToken) {
                const oldUserData = store.state.storeUserDetails.userData;
                const updatedUserData = {
                    ...oldUserData,
                    access_token: errorResponse.refresh_token,
                };

                localStorage.setItem('token', errorResponse.refresh_token);
                store.commit('storeUserDetails/addData', updatedUserData);
                window.location.reload();
            } else {
                removeUserData();
                window.location.reload();
            }
        }
        return Promise.reject(error);
    },
);

export default axios;

import managerDashboard from './dashboard/router';

const managerRoute = [{
    path: '/manager-signup',
    name: 'ManagerSignup',
    component: () => import('./managerSignup.vue'),
    meta: {
        isLogin: true,
        title: 'Become a manager today - Sign up today at getawayGoGo',
    },
}, {
    path: '/manager-login',
    name: 'managerLogin',
    component: () => import('../managerLogin.vue'),
    meta: {
        isLogin: true,
        title: 'Log In - getawayGoGo',
    },
}, {
    path: '/how-to-gogo-property-manager',
    name: 'HowToGogoPropertyManagerGoGo',
    component: () => import('./HowToGogoPropertyManager.vue'),
    meta: {
        title: 'Have Last Minute Nights to Fill? - getawayGoGo',
    },
    children: [
        {
            name: 'ManagerOverview',
            path: 'overview',
            component: () => import('./managerOverview.vue'),
            alias: ['/how-to-gogo-property-manager'],
        },
        {
            name: 'ManagerFrequently',
            path: 'frequently',
            component: () => import('./managerFaq.vue'),
        },
    ],
}, {
    path: '/manager/property-manager',
    name: 'ManagerSteps',
    component: () => import('./signupSteps/ManagerSteps.vue'),
    meta: {
        requiresAuth: true,
        isManager: true,
        checkSteps: true,
        title: 'Manager | Profile',
    },
}];

const routes = managerRoute.concat(managerDashboard);

export default routes;

export default [{
    path: '/how-to/connect-hostfully',
    name: 'connectHostfully',
    component: () => import('./ConnectHostfully.vue'),
    meta: {
        title: 'Connect your Hostfully account - getawayGoGo',
    },
}, {
    path: '/how-to/connect-escapia',
    name: 'connectEscapia',
    component: () => import('./ConnectEscapia.vue'),
    meta: {
        title: 'Connect your Escapia account - getawayGoGo',
    },
}, {
    path: '/how-to/connect-hostaway',
    name: 'connectHostaway',
    component: () => import('./ConnectHostaway.vue'),
    meta: {
        title: 'Connect your Hostaway account - getawayGoGo',
    },
}, {
    path: '/how-to/connect-nextpax',
    name: 'connectNextpax',
    component: () => import('./ConnectNextPax.vue'),
    meta: {
        title: 'Connect your Nextpax account - getawayGoGo',
    },
}, {
    path: '/how-to/connect-hostify',
    name: 'connectHostify',
    component: () => import('./ConnectHostify.vue'),
    meta: {
        title: 'Connect your Hostify account - getawayGoGo',
    },
}, {
    path: '/how-to/connect-ownerrez',
    name: 'connectOwnerrez',
    component: () => import('./ConnectOwnerrez.vue'),
    meta: {
        title: 'Connect your Ownerrez account - getawayGoGo',
    },
}, {
    path: '/how-to/connect',
    name: 'HowToConnect',
    component: () => import('./HowToConnect.vue'),
    meta: {
        title: 'How to Connect PMS',
    },
}];

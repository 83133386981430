export default [{
    path: '/manager',
    name: 'ManagerDashboard',
    component: () => import('./ManagerDashboard.vue'),
    meta: {
        requiresAuth: true,
        isManager: true,
        title: 'Home | Manager - getawayGoGo',
    },
    redirect: { name: 'ManagerHome' },
    children: [{
        name: 'ManagerHome',
        path: 'dashboard',
        component: () => import('./ManagererHome.vue'),
    }, {
        name: 'ManagerInbox',
        path: 'messages',
        component: () => import('./ManagerInbox.vue'),
        meta: { title: 'My Message | Manager - getawayGoGo' },
    }, {
        name: 'ManagerReservation',
        path: 'my-reservation',
        component: () => import('./ManagerReservation.vue'),
        meta: { title: 'Reservations | Manager - getawayGoGo' },
    }, {
        name: 'ManagerDeals',
        path: 'gogo-deals',
        component: () => import('./ManagerDeals.vue'),
        meta: { title: 'GoGo Deals | Manager - getawayGoGo' },
    }, {
        name: 'ManagerProfile',
        path: 'profile',
        component: () => import('./ManagerProfile.vue'),
        meta: { title: 'Profile | Manager - getawayGoGo' },
    }, {
        name: 'ManagerAccount',
        path: 'account',
        component: () => import('./ManagerAccount.vue'),
        meta: { title: 'Account | Manager - getawayGoGo' },
    }, {
        name: 'ManagerHelp',
        path: 'help',
        component: () => import('./ManagerHelp.vue'),
        meta: { title: 'Help | Manager - getawayGoGo' },
    }],
}];

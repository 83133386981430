export default function initializeGoogleAnalytics() {
    if (process.env.NODE_ENV === 'production') {
        // Add preconnect link for better performance
        const preconnectLink = document.createElement('link');
        preconnectLink.href = 'https://www.googletagmanager.com';
        preconnectLink.rel = 'preconnect';
        preconnectLink.crossOrigin = 'anonymous';
        document.head.appendChild(preconnectLink);

        // Dynamically load Google Analytics script
        const script = document.createElement('script');
        script.src = `https://www.googletagmanager.com/gtag/js?id=${process.env.VUE_APP_GA_MEASUREMENT_ID}&`;
        script.async = true;
        document.head.appendChild(script);

        script.onload = () => {
            // Initialize Google Analytics
            window.dataLayer = window.dataLayer || [];
            const gtag = (...args) => {
                window.dataLayer.push(args);
            };
            gtag('js', new Date());
            gtag('config', process.env.VUE_APP_GA_MEASUREMENT_ID);
        };
    }
}

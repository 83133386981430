<template>
    <template v-if="isLoading">
        <loader />
    </template>
    <template v-else>
        <q-layout view="hHh LpR fff">
            <q-header bordered class="bg-white text-black">
                <GGHeader />
            </q-header>

            <q-page-container>
                <router-view />
            </q-page-container>

            <q-footer class="fixed-bottom bg-white text-black">
                <GGFooter />
            </q-footer>
        </q-layout>
    </template>
</template>

<script>
import { useCookies } from 'vue3-cookies';
import _ from 'lodash';
import loader from '@/components/layouts/siteLoader.vue';
import GGHeader from './components/layouts/Header.vue';
import GGFooter from './components/layouts/Footer.vue';

const { cookies } = useCookies();

export default {
    name: 'LayoutDefault',
    components: {
        GGHeader,
        GGFooter,
        loader,
    },
    data() {
        return {
            isLoading: true,
            userLocationData: false,
        };
    },
    watch: {
        $route(to, from) {
            if (to.name !== from.name) {
                this.getUserCurrentLocation();

                if (cookies.isKey('user_login') && _.get(this.$store, 'state.storeUserDetails.userData.is_traveler')) {
                    this.myDealsCount();
                }
            }
        },
    },
    methods: {
        getUserLocationData() {
            const savedData = cookies.get('ip_based_location_data');
            if (cookies.isKey('ip_based_location_data') && savedData) {
                this.userLocationData = savedData;
            }
        },
        getUserCurrentLocation() {
            this.isLoading = true;
            this.getUserLocationData();
            this.axios.get('/api/v2/current-location-by-ip', {
                params: {
                    ip: this.userLocationData.ip,
                    userCacheData: this.userLocationData.user_cache_data,
                },
            }).then((response) => {
                const currentLocation = response.data.data;

                if (!this.userLocationData || this.userLocationData.ip !== currentLocation.ip || this.userLocationData.user_cache_data !== currentLocation.user_cache_data) {
                    cookies.set('ip_based_location_data', currentLocation, '7d');
                    this.$store.commit('storeGlobalDateFormat/addData', currentLocation.date_format);

                    if (!cookies.isKey('user_login') && (!this.userLocationData || this.userLocationData.currency !== currentLocation.currency)) {
                        const currency = { code: currentLocation.currency, symbol: currentLocation.currency_symbol, value: currentLocation.currency };
                        this.$store.commit('storeUserCurrency/addCurrency', currency);
                    }
                }
                this.isLoading = false;
            }).catch(() => {
                this.isLoading = false;
            });
        },
        myDealsCount() {
            this.axios.get('/api/v1/my-deals-count', {
                params: { userId: _.get(this.$store, 'state.storeUserDetails.userData.user_id') },
            }).then((response) => {
                if (response.data.data.room_ids) {
                    this.$store.commit('storeMyDeals/removeAllProperty');
                    response.data.data.room_ids.forEach((deal) => {
                        const dealsData = { roomId: deal };
                        this.$store.commit('storeMyDeals/addProperty', dealsData);
                    });
                }
            });
        },
        // We have remove below code as this is a temporary solucion if currency cache issue
        removeLocalCurrency() {
            const localCurrency = localStorage.getItem('userCurrency');
            if (localCurrency) {
                const currencyObject = JSON.parse(localCurrency);
                const isOriginalSymbol = Object.prototype.hasOwnProperty.call(currencyObject, 'original_symbol');
                if (isOriginalSymbol) {
                    localStorage.removeItem('userCurrency');
                    const currency = {
                        code: 'USD', name: 'US Dollar', symbol: '$', value: 'USD',
                    };
                    this.$store.commit('storeUserCurrency/addCurrency', currency);
                }
            }
        },
    },
    mounted() {
        this.removeLocalCurrency();
    },
};
</script>

<style lang="scss" scoped>
.mainLoader {
    height: 100vh;
}
</style>

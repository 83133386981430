import { createStore } from 'vuex';
import { useCookies } from 'vue3-cookies';
import _ from 'lodash';
import createMultiTabState from 'vuex-multi-tab-state';

const { cookies } = useCookies();

function checkStringArray(stringArray) {
    try {
        const parsedJSON = JSON.parse(stringArray);
        return Array.isArray(parsedJSON);
    } catch (error) {
        return false;
    }
}

const saveDeals = {
    namespaced: true,
    state: () => ({
        properties: _.get(cookies.get('my_deals'), 'length') && checkStringArray(localStorage.getItem('my_deals')) ? JSON.parse(localStorage.getItem('my_deals')) : [],
    }),
    mutations: {
        updateData(state, newData) {
            if (checkStringArray(newData)) state.properties = JSON.parse(newData);
            else state.properties = [];
        },
        addProperty(state, propertyID) {
            if (!_.some(state.properties, propertyID)) {
                cookies.set('my_deals', JSON.stringify(state.properties), 0);
                state.properties.push(propertyID);
                localStorage.setItem('my_deals', JSON.stringify(state.properties));
            }
        },
        removeProperty(state, propertyID) {
            if (_.some(state.properties, { roomId: propertyID })) {
                cookies.set('my_deals', JSON.stringify(state.properties), 0);
                state.properties = _.remove(state.properties, (n) => n.roomId !== propertyID);
                localStorage.setItem('my_deals', JSON.stringify(state.properties));
            }
        },
        removeAllProperty(state) {
            state.properties = [];
            cookies.remove('my_deals');
            localStorage.removeItem('my_deals');
        },
    },
};

const userDetails = {
    namespaced: true,
    state: () => ({
        userData: cookies.isKey('user_login') && localStorage.getItem('userDetails') ? JSON.parse(localStorage.getItem('userDetails')) : {},
    }),
    mutations: {
        addData(state, userData) {
            state.userData = userData;
            localStorage.setItem('userDetails', JSON.stringify(userData));
        },
        removeData(state) {
            state.userData = {};
            localStorage.removeItem('userDetails');
        },
    },
};

const hideProperties = {
    namespaced: true,
    state: () => ({
        properties: _.get(cookies.get('hiddenPropertyList'), 'length') ? cookies.get('hiddenPropertyList').split(',').map((id) => parseInt(id, 10)) : [],
    }),
    mutations: {
        addAllProperties(state, properties) {
            state.properties = properties;
            cookies.set('hiddenPropertyList', state.properties, 0);
        },
        addProperty(state, propertyID) {
            state.properties.push(propertyID);
            decodeURI(cookies.get('hiddenPropertyList'));
            cookies.set('hiddenPropertyList', state.properties, 0);
        },
        removeProperty(state, propertyID) {
            state.properties = _.remove(state.properties, (n) => n !== propertyID);
            cookies.set('hiddenPropertyList', state.properties, 0);
        },
    },
};

const userCurrency = {
    namespaced: true,
    state: () => ({
        userCurrency: localStorage.getItem('userCurrency') ? JSON.parse(localStorage.getItem('userCurrency')) : {
            code: 'USD', name: 'US Dollar', symbol: '$', value: 'USD',
        },
    }),
    mutations: {
        addCurrency(state, userCurrencyData) {
            state.userCurrency = userCurrencyData;
            localStorage.setItem('userCurrency', JSON.stringify(state.userCurrency));
        },
    },
};

const currencyList = {
    namespaced: true,
    state: () => ({
        currencyList: localStorage.getItem('currencyList') ? JSON.parse(localStorage.getItem('currencyList')) : [],
    }),
    mutations: {
        currencyList(state, userCurrencyData) {
            state.currencyList = userCurrencyData;
            localStorage.setItem('currencyList', JSON.stringify(state.currencyList));
        },
    },
};

const stepData = {
    namespaced: true,
    state: () => ({
        userStepsData: cookies.isKey('user_login') && localStorage.getItem('userStepsData') ? JSON.parse(localStorage.getItem('userStepsData')) : {},
    }),
    mutations: {
        addData(state, userStepsData) {
            state.userStepsData = userStepsData;
            localStorage.setItem('userStepsData', JSON.stringify(state.userStepsData));
        },
        removeData(state) {
            state.userStepsData = {};
            localStorage.removeItem('userStepsData');
        },
    },
};

const globalDateFormat = {
    namespaced: true,
    state: () => ({
        dateFormat: localStorage.getItem('globalDateFormat') ? localStorage.getItem('globalDateFormat') : 'MM/dd/yyyy',
    }),
    mutations: {
        addData(state, dateFormat) {
            state.dateFormat = dateFormat;
            localStorage.setItem('globalDateFormat', state.dateFormat);
        },
    },
};

const filterData = {
    namespaced: true,
    state: () => ({
        propertyTypeList: [],
        amenitiesList: [],
    }),
    mutations: {
        addPropertyTypeList(state, propertyTypeList) {
            state.propertyTypeList = propertyTypeList;
        },

        addAmenitiesList(state, amenitiesList) {
            state.amenitiesList = amenitiesList;
        },
    },
};

const additionalServices = {
    namespaced: true,
    state: () => ({
        optionalServices: [],
    }),
    mutations: {
        addServices(state, services) {
            state.optionalServices = services;
        },
    },
};

const appBuildVersion = {
    namespaced: true,
    state: () => ({
        version: localStorage.getItem('appBuildVersion') ? localStorage.getItem('appBuildVersion') : '',
    }),
    mutations: {
        updateBuildVersion(state, version) {
            state.version = version;
            localStorage.setItem('appBuildVersion', state.version);
        },
    },
};

const store = createStore({
    modules: {
        storeMyDeals: saveDeals,
        storeUserDetails: userDetails,
        storeHideProperties: hideProperties,
        storeUserCurrency: userCurrency,
        storeCurrencyList: currencyList,
        storeStepsData: stepData,
        storeGlobalDateFormat: globalDateFormat,
        storeFilterData: filterData,
        storeOptionalServices: additionalServices,
        storeAppBuildVersion: appBuildVersion,
    },
    plugins: [
        createMultiTabState({
            statesPaths: ['storeUserDetails.userData', 'storeMyDeals.properties', 'storeStepsData.userStepsData', 'storeUserCurrency.userCurrency'],
        }),
    ],
});

export default store;

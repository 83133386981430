<template>
    <q-dialog persistent v-model="feedbackForm" @hide="$emit('feedbackModel')">
        <q-card class="generalModelStyle feedbackFormPopup">
            <div class="q-pa-md">
                <div class="row no-wrap items-start">
                    <q-toolbar-title class="text-center q-pr-none">
                        <img :src="`${awsPath}site/images/logo.webp`" alt="logo">
                    </q-toolbar-title>
                    <q-btn flat dense color="red-8" class="btn--no-hover" icon="close" size="1rem" v-close-popup />
                </div>
                <q-separator inset />
                <div class="feedbackTitle">{{ isContactForm ? 'Contact Us' : 'Feedback about getawayGoGo' }}</div>

                <q-form @submit.prevent="submitForm">
                    <div class="q-gutter-y-md q-mt-sm">
                        <q-input
                            v-model.trim="userName"
                            placeholder="Your Name"
                            outlined
                            hide-bottom-space
                            @blur="removeErrorMsg('quick_feedback_full_name')"
                            :error="isErrorAvailable('quick_feedback_full_name')"
                            :error-message="isErrorAvailable('quick_feedback_full_name') ? setErrorMsg('quick_feedback_full_name') : ''"
                            :rules="[val => !!val || 'Please enter a Name.']" />

                        <q-input
                            v-model="userEmail"
                            placeholder="Your Email Address"
                            outlined
                            hide-bottom-space
                            @blur="removeErrorMsg('quick_feedback_email')"
                            :error="isErrorAvailable('quick_feedback_email')"
                            :error-message="isErrorAvailable('quick_feedback_email') ? setErrorMsg('quick_feedback_email') : ''"
                            :rules="[val => customValidations.emailValidation(val)]" />

                        <q-input
                            type="textarea"
                            v-model.trim="userMessage"
                            hide-bottom-space
                            outlined
                            placeholder="Type Your Message Here..."
                            rows="3"
                            @blur="removeErrorMsg('quick_feedback_message')"
                            :error="isErrorAvailable('quick_feedback_message')"
                            :error-message="isErrorAvailable('quick_feedback_message') ? setErrorMsg('quick_feedback_message') : ''"
                            :rules="[val => !!val || 'Please enter a Message.']" />

                        <vue-recaptcha :sitekey="recaptchaKey" @verify="captchaResponse" @expired="captchToken = null" />
                    </div>
                    <span v-if="captchaErrorMsg || isErrorAvailable('g_recaptcha_response')" class="text-ggg-red smallText">{{ captchaErrorMsg || setErrorMsg('g_recaptcha_response') }}</span>
                    <div class="text-center">
                        <q-btn dense rounded type="submit" label="Submit" :loading="btnLoading" class="submitBtn btn btn--no-hover" no-caps />
                    </div>
                </q-form>
            </div>
        </q-card>
    </q-dialog>

    <q-dialog persistent v-model="successPopup">
        <q-card v-if="successPopup" class="generalModelStyle popupModelStyle">
            <div class="actionIcon q-mb-md">
                <img :src="`${awsPath}site/images/success-model-icon.webp`" alt="success">
            </div>
            <h5>Success!</h5>
            <p>{{ modelMessage }}</p>
            <q-btn dense label="ok" class="okBtn q-mt-md btn--no-hover" @click="feedbackForm = false" v-close-popup />
        </q-card>
    </q-dialog>

    <q-dialog persistent v-model="errorPopup">
        <q-card v-if="errorPopup" class="generalModelStyle popupModelStyle">
            <div class="actionIcon q-mb-md">
                <img :src="`${awsPath}site/images/error-model-icon.webp`" alt="success">
            </div>
            <h5>Error!</h5>
            <p>{{ modelMessage }}</p>
            <q-btn dense label="ok" class="okBtn q-mt-md btn--no-hover" @click="errorPopup = false" v-close-popup />
        </q-card>
    </q-dialog>
</template>

<script>
import { VueRecaptcha } from 'vue-recaptcha';

export default {
    name: 'feedbackForm',
    components: { VueRecaptcha },
    props: {
        prop_show_feedback: {
            type: Boolean,
            default: false,
        },
        propFormType: {
            type: String,
            default: 'feedback',
        },
    },
    emits: ['feedbackModel'],
    watch: {
        prop_show_feedback(newVal) {
            this.feedbackForm = newVal;
        },
        userName(newVal) {
            if (newVal) this.userName = this.customValidations.allowOneSpace(newVal);
        },
        userMessage(newVal) {
            if (newVal) this.userMessage = this.customValidations.allowOneSpace(newVal);
        },
    },
    data() {
        return {
            feedbackForm: this.prop_show_feedback,
            userName: null,
            userEmail: null,
            userMessage: null,
            recaptchaKey: process.env.VUE_APP_RECAPTCHA_SITE_KEY,
            successPopup: false,
            errorPopup: false,
            modelMessage: null,
            captchToken: null,
            captchaErrorMsg: null,
            errorMessages: null,
            btnLoading: false,
        };
    },
    methods: {
        captchaResponse(event) {
            this.captchaErrorMsg = null;
            this.captchToken = event;
            this.removeErrorMsg('g_recaptcha_response');
        },
        submitForm() {
            if (!this.captchToken) {
                this.captchaErrorMsg = 'Please fill out the captcha code.';
            } else if (this.isContactForm) {
                this.submitContact();
            } else if (!this.errorMessages || !Object.keys(this.errorMessages).length) {
                this.submitFeedback();
            }
        },
        submitContact() {
            this.btnLoading = true;
            const payload = {
                isApi: true,
                name: this.userName,
                email: this.userEmail,
                feedback: this.userMessage,
                recaptchaResponse: this.captchToken,
            };

            this.axios.post('/api/v1/property-manager/contact-us', payload).then((response) => {
                this.captchaErrorMsg = null;
                if (response.data.status_code === 200 || this.isContactForm) {
                    this.modelMessage = response.data.message;
                    this.successPopup = true;
                } else {
                    this.modelMessage = response.data.message;
                    this.errorPopup = true;
                }
            }).catch((error) => {
                this.captchaErrorMsg = error.response.data.message;
                if (this.isContactForm) {
                    if (error.response.data.errors) {
                        const message = Object.values(error.response.data.errors)[0];
                        [this.captchaErrorMsg] = message;
                    }
                }
            }).then(() => {
                this.btnLoading = false;
            });
        },
        submitFeedback() {
            this.btnLoading = true;
            this.captchaErrorMsg = null;
            const payload = {
                quick_feedback_full_name: this.userName,
                quick_feedback_email: this.userEmail,
                quick_feedback_message: this.userMessage,
                g_recaptcha_response: this.captchToken,
            };

            this.axios.post('/api/quick_feedback', payload).then((response) => {
                this.modelMessage = response.data.message;
                this.successPopup = true;
            }).catch((error) => {
                const errorResponse = error.response;
                if (errorResponse.status === 400) {
                    [this.captchaErrorMsg] = errorResponse.data.errors;
                } else {
                    [this.errorMessages] = errorResponse.data.errors;
                }
            }).then(() => {
                this.btnLoading = false;
            });
        },
        setErrorMsg(field) {
            if (!this.errorMessages || !this.errorMessages[field]) return false;
            return this.errorMessages[field][0];
        },
        isErrorAvailable(field) {
            if (this.setErrorMsg(field)) return true;
            return false;
        },
        removeErrorMsg(field) {
            if (!this.errorMessages || !this.errorMessages[field]) return false;
            return delete this.errorMessages[field];
        },
    },
    computed: {
        isContactForm() {
            return this.propFormType === 'contact';
        },
    },
};
</script>

<style lang="scss" scoped>
.feedbackFormPopup {
    width: 390px;

    img {
        max-width: 70%;
        height: auto;
    }

    .feedbackTitle {
        margin-top: 1rem;
        font-size: 1.25rem;
        text-align: center;
        line-height: 1.2;
    }

    .submitBtn {
        margin-top: 1rem;
        padding: .25rem 2rem;
        font-size: 1rem;
        border-radius: 1.25rem;
    }
}

:deep(.q-textarea .q-field__native) {
    resize: none !important;
}

:deep(.q-field .q-field__native::placeholder) {
    color: #b8b8b8;
    opacity: 1;
}

:deep(.q-field .q-field__control) {
    border-radius: 0.5rem;
    font-size: 1rem;
    color: #565a5c;

    &::before {
        border-radius: 0.5rem;
        border-color: #cccccc78;
    }

    &:hover::before {
        border-color: #cccccc78;
    }
}

:deep(.q-field--outlined.q-field--highlighted .q-field__control:hover:before) {
    border-color: #cccccc78;
}

:deep(.q-field--outlined.q-field--highlighted .q-field__control::after) {
    border: unset;
}
</style>

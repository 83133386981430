import travelerDashboard from './dashboard/router';

const travelerRoute = [{
    path: '/traveler-signup',
    name: 'TravelerSignup',
    component: () => import('./travelerSignup.vue'),
    meta: {
        isLogin: true,
        title: 'Get Last Minute Deals - Sign up today at getawayGoGo',
    },
}, {
    path: '/traveler-login',
    name: 'travelerLogin',
    component: () => import('../login.vue'),
    meta: {
        isLogin: true,
        title: 'Log In - getawayGoGo',
    },
}, {
    path: '/how-to-gogo-traveler',
    name: 'HowToGogoTraveler',
    component: () => import('./HowToGogoTraveler.vue'),
    meta: {
        title: 'Find Best Last Minute Vacation Rental Deals - getawayGoGo',
    },
    children: [
        {
            name: 'TravelerOverview',
            path: 'overview',
            component: () => import('./travelerOverview.vue'),
            alias: ['/how-to-gogo-traveler'],
        },
        {
            name: 'TravelerFrequently',
            path: 'frequently',
            component: () => import('./travelerFaq.vue'),
        },
    ],
}];

const routes = travelerRoute.concat(travelerDashboard);

export default routes;

<template>
    <div class="footer">
        <div class="row q-px-container footer-top">
            <div class="col-12 q-px-md" :class="$q.screen.lt.sm ? 'q-pb-none' : 'q-pb-lg'">
                <h4>getawayGoGo - Last Minute Vacation Rental Deals</h4>
                <span>Find last minute vacation rental discounts, specials, and deals in your favorite places with book direct pricing and no site service fees or markups on getawayGoGo.</span>
            </div>

            <div class="col-12 col-sm-6 col-md-3 q-px-md">
                <h4>getawayGoGo</h4>
                <ul class="footer_column">
                    <li><router-link :to="{ name: 'AboutUs' }" class="item-link">About Us</router-link></li>
                    <li v-if="isManager"><router-link :to="{ name: 'managerContact' }" class="item-link">Contact Us</router-link></li>
                    <li><router-link :to="{ name: 'TermsOfService' }" class="item-link">Terms of Service</router-link></li>
                    <li><router-link :to="{ name: 'PrivacyPolicy' }" class="item-link">Privacy Policy</router-link></li>
                </ul>
            </div>

            <div class="col-12 col-sm-6 col-md-3 q-px-md">
                <h4>Traveler Information</h4>
                <ul class="footer_column">
                    <li v-for="(menu, i) in footerCol2" :key="i">
                        <router-link v-if="menu.link" :to="menu.link" class="item-link">{{ menu.label }}</router-link>
                        <button
                            v-else-if="menu.action"
                            type="button"
                            class="menu-button last-menu-item item-link cursor-pointer"
                            @click="isLogin ? $router.push({ name: 'TravelerHome' }) : userType = menu.action, showLoginModal = true">
                            {{ menu.label }}
                        </button>
                    </li>
                    <li>
                        <a class="item-link" href="https://www.babyquip.com/?a=58e513f" target="_blank" rel="noopener noreferrer">Baby Equipment Rentals</a>
                    </li>
                </ul>
            </div>

            <div class="col-12 col-sm-6 col-md-3 q-px-md">
                <h4>Property Manager Information</h4>
                <ul class="footer_column">
                    <li v-for="(menu, i) in footerCol3" :key="i">
                        <router-link v-if="menu.link" :to="menu.link" class="item-link">{{ menu.label }}</router-link>
                        <button
                            type="button"
                            v-else-if="menu.action"
                            class="menu-button last-menu-item item-link cursor-pointer"
                            @click="isLogin ? $router.push({ name: 'ManagerHome' }) : userType = menu.action, showLoginModal = true">
                            {{ menu.label }}
                        </button>
                    </li>
                </ul>
            </div>

            <div class="col-12 col-sm-6 col-md-3 q-px-md">
                <h4>Follow Us</h4>
                <div class="shareLink">
                    <a href="https://www.facebook.com/getawaygogorentals" aria-label="Facebook share" class="socialIcon" target="_blank" rel="noopener noreferrer">
                        <q-icon size="sm" name="fab fa-facebook-square" />
                    </a>
                    <a href="https://www.instagram.com/GetawayGoGo" aria-label="Instagram share" class="socialIcon" target="_blank" rel="noopener noreferrer">
                        <q-icon size="sm" name="fab fa-instagram" />
                    </a>
                    <a href="https://www.linkedin.com/company/getawaygogo" aria-label="Linkdin share" class="socialIcon" target="_blank" rel="noopener noreferrer">
                        <q-icon size="sm" name="fab fa-linkedin" />
                    </a>
                    <a href="https://twitter.com/getawaygogo" aria-label="Twitter share" class="socialIcon" target="_blank" rel="noopener noreferrer">
                        <q-icon size="sm" name="fab fa-brands fa-square-x-twitter" />
                    </a>
                    <a href="https://www.youtube.com/channel/UClps7y5W5fpMjhGS3taFGfQ" aria-label="Twitter share" class="socialIcon" target="_blank" rel="noopener noreferrer">
                        <q-icon size="sm" name="fab fa-youtube" />
                    </a>
                </div>

                <div class="footerAppDownload">
                    <p>Download getawayGoGo for mobile</p>
                    <div class="row no-wrap justify-between appDownload">
                        <div class="iosAppDownload">
                            <img :src="`${awsPath}site/images/home/appstore.webp`" @click="appPopup = true; appstore = true" alt="appstore">
                        </div>
                        <div class="androidAppDownload">
                            <img :src="`${awsPath}site/images/home/googleplay.webp`" @click="appPopup = true; appstore = false" alt="googleplay">
                        </div>
                    </div>

                    <q-dialog v-model="appPopup">
                        <q-card class="generalModelStyle q-pa-md">
                            <div align="right">
                                <q-btn icon="close" class="btn--no-hover" size=".75rem" :ripple="false" flat dense v-close-popup />
                            </div>
                            <h6 class="text-center">Download getawayGoGo<br />Last Minute App</h6>
                            <div>
                                <img :src="`${awsPath}site/images/home/` + (appstore ? 'AppleQCode' : 'GoogleQCode') + '.webp'" class="qrCode" alt="QR Code">
                                <div class="fit row items-center">
                                    <a :href="appstore ? appStoreLink : playStoreLink" target="_blank" rel="noopener noreferrer">
                                        <img :src="`${awsPath}site/images/home/` + (appstore ? 'appstore' : 'googleplay') + '.webp'" class="downloadApp" alt="Download App">
                                    </a>
                                    <img class="appShare" :src="`${awsPath}site/images/home/app_share.webp`" alt="share app" @click="appSharePopup = true; appPopup = false">
                                </div>
                            </div>
                        </q-card>
                    </q-dialog>

                    <q-dialog v-model="appSharePopup">
                        <q-card class="generalModelStyle q-pa-md">
                            <div align="right" class="row no-wrap items-start">
                                <h5 class="text-bold text-center q-mt-md q-mb-sm">Share getawayGoGo App</h5>
                                <q-btn icon="close" class="btn--no-hover" size=".75rem" :ripple="false" flat dense v-close-popup />
                            </div>
                            <div>
                                <ul class="appShareList">
                                    <li><a class="shareIcon" href="javascript:void(0)" @click="copyLink(appstore ? appStoreLink : playStoreLink)">
                                        <h6><img :src="`${awsPath}site/images/copy_link.webp`" alt="copy link">Copy Link</h6>
                                    </a></li>
                                    <li><a class="shareIcon" target="_blank" rel="noopener noreferrer" :href="'mailto:?subject=getawayGoGo&body=' + (appstore ? appStoreLink : playStoreLink)">
                                        <h6><img style="height: 1.25rem" :src="`${awsPath}site/images/email.webp`" alt="Email">Email</h6>
                                    </a></li>
                                    <li><a class="shareIcon" :href="'sms:?&body=' + (appstore ? appStoreLink : playStoreLink)">
                                        <h6><img :src="`${awsPath}site/images/iphone_message.webp`" alt="Message">Messages</h6>
                                    </a></li>
                                    <li><a class="shareIcon" href="javascript:void(0)" @click="copyInstaLink(appstore ? appStoreLink : playStoreLink)">
                                        <h6><img :src="`${awsPath}site/images/instagram.webp`" alt="Instagram">Instagram</h6>
                                    </a></li>
                                    <li><a class="shareIcon" target="_blank" rel="noopener noreferrer" :href="'http://www.facebook.com/sharer.php?u=' + (appstore ? appStoreLink : playStoreLink)">
                                        <h6><img :src="`${awsPath}site/images/facebook.webp`" alt="facebook">Facebook</h6>
                                    </a></li>
                                    <li><a class="shareIcon" target="_blank" rel="noopener noreferrer" :href="'https://twitter.com/share?url=' + (appstore ? appStoreLink : playStoreLink)">
                                        <h6><q-icon size="sm" name="fab fa-brands fa-x-twitter" />Twitter</h6>
                                    </a></li>
                                </ul>
                            </div>
                        </q-card>
                    </q-dialog>
                </div>
            </div>
        </div>

        <div class="footer-bottom">
            <div class="q-pa-md q-px-container" :class="{ 'text-center': $q.screen.lt.md }">&copy; getawayGoGo, Inc.</div>
        </div>

        <div class="feedbackButton">
            <q-btn round class="btn helpBtn btn--no-hover" :style="{ bottom: this.helpBtnBottom }" @click="showFeedbackModal = true">
                <img
                    class="helpIcon"
                    :src="`${awsPath}site/images/help.webp`"
                    :srcset="`${awsPath}site/images/help.webp 1x, ${awsPath}site/images/help@2x.webp 2x`"
                    alt="help"
                    width="22"
                    height="25"
                />
            </q-btn>
        </div>

        <div v-if="acceptCookie" class="fixed-bottom cookieBar">
            <q-card square class="cookieContainer">
                <q-card-section :horizontal="$q.screen.gt.xs">
                    <div class="cookieContent">getawayGoGo uses cookies and similar technologies to optimize your user experience, customize deal recommendations and provide an optimized user
                        experience. By using this website you agree to accept all cookies and agree to the use of cookies and our full privacy policy which includes sharing the information obtained
                        from them. <router-link :to="{ name: 'PrivacyPolicy' }">Details</router-link></div>
                    <div class="cookieBtn text-center">
                        <q-btn no-caps flat :ripple="false" class="cookieBarBtn btn--no-hover" @click="acceptCookiesfn()" label="Accept Cookies" />
                    </div>
                </q-card-section>
            </q-card>
        </div>

        <Login v-if="showLoginModal && !isLogin" :prop_user_type="userType" :prop_show_modal="showLoginModal" @loginModalhidden="userType = null, showLoginModal = false" />
        <feedbackForm v-if="showFeedbackModal" :prop_show_feedback="showFeedbackModal" @feedbackModel="showFeedbackModal = false" />
    </div>
</template>

<style lang="scss" scoped>
.menu-button {
    all: unset;
    padding: 3px 0;
    line-height: 1.8em;
}

.footer {
    background-color: #d0d0d0;
}

.footer-top {
    padding-top: 2rem;
    padding-bottom: 1.5rem;

    ul.footer_column {
        list-style: none;
        margin: unset;
        padding: unset;
    }

    p {
        margin-bottom: 0.35rem;
        padding-top: 1rem;
        font-size: 1rem;
    }

    h4 {
        font-weight: 700;
        margin: 0 0 0.5rem;
        font-size: 1.125rem;
    }

    .shareLink {
        margin: .75rem 0 .5rem;

        .socialIcon {
            margin-right: .25rem;
            color: #000;
            text-decoration: none;
        }
    }

    li a.item-link {
        display: inline-block;
        text-decoration: none;
        color: #000;
        font-size: 0.938rem;

        &:hover {
            color: $ggg-red;
        }
    }

    li:not(:last-child) {
        padding: 3px 0;
    }
}

.iosAppDownload img,
.androidAppDownload img {
    padding-right: 1rem;
    cursor: pointer;
    width: 100%;
    height: auto;
    object-fit: cover;
}

.qrCode {
    width: 250px;
}

.downloadApp {
    max-width: 200px;
    padding-top: 1rem;
}

.appShareList {
    margin: auto 1rem;
    padding: unset;
    list-style: none;

    a.shareIcon {
        text-decoration: none;
        color: #484848;

        &:hover {
            color: #888;
        }

        h6 {
            display: inline-flex;
        }
    }

    .shareIcon img,
    .shareIcon .q-icon {
        height: 25px;
        margin-right: 1.25rem;
    }
}

h6 {
    margin: 0 0 0.5rem;
}

.footer-bottom {
    background: $footer-bg-color;
    border-top: 1px solid #ddd;
    font-size: .875rem;
}

.cookieBar .cookieContainer {
    padding: 0.75rem 1.688rem;
    font-size: 0.75rem;
    border-top: 1px solid #0000001f;

    .cookieContent {
        width: 80%;

        a {
            color: unset;
        }
    }

    .cookieBtn {
        width: 20%;
    }

    .cookieBarBtn {
        width: 65%;
        padding: 0.438rem 0.938rem;
        background: #b9b9b9;
        font-size: 0.875rem;
        font-weight: bold;
        border: 1px solid #b9b9b9;

        &:hover {
            background-color: #909090;
            color: #fff;
            border: 1px solid #285E8E;
        }
    }
}

.feedbackButton .helpBtn {
    width: 2.625rem;
    height: 2.625rem;
    z-index: 9;
    padding: 9px;
    position: fixed;
    right: 2vh;

    img.helpIcon {
        width: 1.375rem;
    }
}

@media only screen and (min-width: $breakpoint-lg) {
    .footer-top {
        li a.item-link {
            font-size: 1rem;
        }

        h4 {
            font-size: 1.375rem;
        }
    }

    .cookieBar .cookieContainer {
        font-size: .875rem;
    }
}

@media only screen and (max-width:1199px) {
    .cookieBar .cookieContainer .cookieBarBtn {
        width: 68%;
        padding: 0.438rem 0.125rem;
        font-size: 0.75rem;
    }
}

@media only screen and (max-width:$breakpoint-sm) {
    .footer-top h4 {
        margin-top: 1rem;
        font-size: 1.25rem;
    }
}

@media only screen and (max-width: $breakpoint-xs) {
    .cookieBar .cookieContainer {
        padding: unset;

        :deep(.q-card__section--vert) {
            padding: 0.5rem;
        }

        .cookieContent {
            width: 100%;
            padding-bottom: .625rem;
            font-size: .625rem;
        }

        .cookieBtn {
            width: 100%;

            .cookieBarBtn {
                width: 30%;
                font-size: .625rem;
            }
        }
    }

    .qrCode {
        width: 190px;
    }

    .downloadApp {
        width: 150px;
    }

    .appShare {
        max-width: 45px;
    }
}

@media only screen and (max-width: 480px) {
    .cookieBar .cookieContainer .cookieBtn .cookieBarBtn {
        width: 40%;
    }
}
</style>

<script>
import _ from 'lodash';
import { useCookies } from 'vue3-cookies';
import Login from '../login.vue';
import feedbackForm from '../feedback.vue';

const { cookies } = useCookies();

export default {
    name: 'GGFooter',
    components: { Login, feedbackForm },
    data() {
        return {
            footerCol2: [{
                label: 'How to getawayGoGo',
                link: '/how-to-gogo-traveler',
            }, {
                label: 'Traveler FAQ',
                link: '/how-to-gogo-traveler/frequently',
            }, {
                label: 'Traveler Sign Up',
                link: '/traveler-signup',
            }, {
                label: 'Traveler Login',
                action: 'traveler',
            }],
            footerCol3: [{
                label: 'How to getawayGoGo',
                link: '/how-to-gogo-property-manager',
            }, {
                label: 'Property Manager Pricing',
                link: '/pricing',
            }, {
                label: 'How to Connect Your PMS',
                link: '/how-to/connect',
            }, {
                label: 'Property Manager FAQ',
                link: '/how-to-gogo-property-manager/frequently',
            }, {
                label: 'Property Manager Sign Up',
                link: '/manager-signup',
            }, {
                label: 'Property Manager Login',
                action: 'manager',
            }],
            showLoginModal: false,
            userType: null,
            acceptCookie: !cookies.isKey('accept_cookie'),
            appStoreLink: 'https://apps.apple.com/us/app/getawaygogo/id1624224361?platform=iphone',
            playStoreLink: 'https://play.google.com/store/apps/details?id=com.getawaygogo',
            appPopup: false,
            appstore: false,
            appSharePopup: false,
            showFeedbackModal: false,
            helpBtnBottom: '2vh',
            isLogin: cookies.isKey('user_login'),
            isManager: false,
        };
    },
    watch: {
        '$store.state.storeUserDetails.userData': function(newVal) {
            if (newVal !== null && !newVal.is_traveler) {
                this.isManager = true;
            }
        },
    },
    methods: {
        acceptCookiesfn() {
            this.acceptCookie = false;
            cookies.set('accept_cookie', 1, 0);
            this.helpBtnBottom = '2vh';
        },
        copyLink(link) {
            navigator.clipboard.writeText(link);
            this.appSharePopup = false;
            this.$q.notify({
                icon: 'check_circle', color: 'black', position: 'bottom', message: 'Link copied successfully',
            });
        },
        copyInstaLink(link) {
            navigator.clipboard.writeText(link);
            this.appSharePopup = false;
            window.open('https://www.instagram.com', '_blank');
        },
    },
    mounted() {
        if (document.querySelector('.cookieBar')) this.helpBtnBottom = `${document.querySelector('.cookieBar').clientHeight + 10}px`;
        if (this.isLogin && !_.get(this.$store, 'state.storeUserDetails.userData.is_traveler')) this.isManager = true;
    },
};
</script>
